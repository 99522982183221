import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import IndexHeader from '@/components/en/m-header.vue'
import MDivider from '@/components/en/m-divider.vue'

import { getFavoriteGoodsList } from '@/api/en/favorite.js'
import { getHistory, getHotSearch, delHistory, searchGoods,search_mini, searchGift, searchMerchant, searchPresale, cut_search } from '@/api/en/search.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
    components: { GoodsColumns, MSku, MAnimate, CartFlex, IndexHeader, MDivider },
    data() {
        return {
            searckKey: '',
            isSearch: false,
            recomnd:8,        // 模拟为你推荐数据
            start: false,      // 是否开启加入购物车动画
            start_dom: null,   // 开启动画的起点DOM 
            skuModal: false,    // 控制显示 选择sku加入购物车面板
            showFavorite: false,
            historyList: [],
            hotList: [],
            goodsList: [],
            GuessList: [],
            gPage: 1,
            islock: false,
            noMore: false,
            sortType: 1,
            floading: false,
            fislock: false,
            finished: false,
            fpage: 1,
            favoriteGoodsList: [],
            goodsId: 0,
            searchType: '',
            merchantId: '',
            aloading: true,
            moreList: []
        };
    },
    methods: {
        goodsHandleScroll(e){
            if(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 50 && this.isSearch){
                if(!this.islock && !this.noMore){
                    e.preventDefault()
                    e.stopPropagation()
                    this.islock = true
                    this.gPage++
                    this.judgeSearchMethods()
                }
            }
        },
        favoriteHandleScroll(e){
            if(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 50){
                if(!this.fislock && !this.finished){
                    e.preventDefault()
                    e.stopPropagation()
                    this.fislock = true
                    this.fpage++
                    this.getFavoriteGoodsListHandle()
                }
            }
        },
        onSearch() {
            // 点击键盘搜索
            this.isSearch = true
            this.noMore = false
            this.page = 1
            this.gPage = 1
            this.goodsList = []
            if (window.location.href.split('s=presale&key=')[1] != '' && window.location.href.split('s=presale&key=')[1] != undefined) {
                this.$router.replace({
                    path: "/en/search",
                    query: { s: "presale", key: this.searckKey},
                }) 
            }else{
               if (this.searckKey) {
                this.$router.replace({
                    path: "/en/search",
                    query: { searchKey: encodeURIComponent(this.searckKey) },
                 })
               }  
            }
            this.judgeSearchMethods()
            this.getGuessLike()
        },
        onCancel() {
            // 点击取消
            let back = localStorage.getItem('back_path')
            if(!back) {
                back = '/'
            }

            this.$router.push({path: back})
        },
        onDelete(id = null, index = 0) {
            // 删除历史搜索
            if(id) {
                this.deleteHandle(id, index)
                return false
            }
            this.$dialog.confirm({
                title: 'Warning',
                message: 'Are you sure to clear search history?',
                cancelButtonText: 'Cancle',
                confirmButtonText: 'Confirm'
            })
            .then(() => {
                // on confirm
                this.deleteHandle()
            })
            .catch(() => {
               
            });
        },
        deleteHandle(id = null, index = 0) {
            delHistory(id? id : '').then(res => {
                if(res) {
                    if(id) {
                        this.historyList.splice(index, 1)
                    } else {
                        this.historyList = []
                    }
                    this.$notify({ type: 'success', message: 'Success' });
                }
            })
        },
        gotoCart() {
            // 前往购物车
            this.$router.push({path: '/en/cart'})
        },
        backStep() {
            if(this.$route.query.b && this.$route.query.b == 1) {
                this.$router.back()
                return false
            }
            // 返回上一步
            if((!this.searchType || this.searchType == '') && this.isSearch) {
                this.isSearch = false
                this.$router.replace('/en/search')
            }else{
                this.$router.back()
            }
        },
        handleCart(goods, e){
            // 商品列表购物车点击事件
            this.goodsId = goods
            this.skuModal  = true
            this.start_dom = e 
        },
        handleCartSuccess(){
            // sku 弹窗加入购物车成功回调
            this.skuModal = false
            let timer = setTimeout( () => {
                this.start  = true
                clearTimeout(timer)
            },300)
        
        },
        handleCartEnd(){
            // 加入购物车动画结束
            this.start = false
        },
        getHistoryHandle() {
            // 获取搜索历史
            getHistory().then(res => {
                if(res) {
                    this.historyList = res.data
                }
            })
        },
        getHotSearchHandle() {
            // 获取热门搜索
            getHotSearch().then(res => {
                if(res) {
                    this.hotList = res.data
                }
            })
        },
        searchKeyHandle(key) {
            // 搜索
            this.searckKey = decodeURIComponent(key)
            this.isSearch = true
            this.noMore = false
            this.goodsList = []
            if(!this.$route.query.b && !this.$route.query.skey) {
                this.$router.replace({
                    path: "/en/search",
                    query: { searchKey: encodeURIComponent(this.searckKey) },
                })
            }
            this.judgeSearchMethods()
        },
        onClickSearch(e) {
            this.gPage = 1
            this.sortType = 1
            this.goodsList = []
            this.searckKey = e
            this.searchGoodsHandle(e)
        },
        cutSearch() {
            cut_search({keywords: decodeURIComponent(this.searckKey.trim())}).then(res => {
                if(res.code == 20000) {
                    this.moreList = res.data
                }
            })
        },
        searchGoodsHandle() {
            // 搜索商品
            this.aloading = true
            let params = {
                search: decodeURIComponent(this.searckKey.trim()),
                status: this.sortType,
                page: this.gPage
            }
            if (this.$store.state.isMiniProgram) {
                search_mini(params).then(res => {
                    this.islock = false
                    if (res) {
                        this.goodsList = this.goodsList.concat(res.data.data)
                    } else {
                        this.noMore = true
                    }
                }).catch(() => {
                    this.goodsList = []
                }).finally(() => {
                    this.aloading = false
                })
            } else {
                searchGoods(params).then(res => {
                    this.islock = false
                    if(res) {
                        this.goodsList = this.goodsList.concat(res.data.data)
                    }else{
                        this.noMore = true
                    }
                }).catch(() => {
                    this.goodsList = []
                }).finally(() => {
                    this.aloading = false
                })
            }
            this.cutSearch()
        },
        changeSortType(sort) {
            // 修改排序方法
            if(this.sortType == 4 && sort == 4) {
                sort = 5
            }
            this.sortType = sort
            this.goodsList = []
            this.gPage = 1
            this.noMore = false

            this.judgeSearchMethods()
        },
        judgeSearchMethods() {
            // 判断搜索方法
            if(this.searchType == 'gift') {
                this.searchGiftHandle()
            }
            else if(this.searchType == 'merchant') {
                this.searchMerchant()
            }
            else if(this.searchType == 'presale') {
                this.searchPresaleHandle()
            }
            else {
                this.searchGoodsHandle()
            }
        },
        searchGiftHandle() {
            // 搜索礼物专区商品
            let params = {
                search: this.searckKey,
                status: this.sortType,
                page: this.gPage
            }
            searchGift(params).then(res => {
                this.islock = false
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                }else{
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },
        searchPresaleHandle() {
            // 搜索预售
            this.aloading = true
            let params = {
                search: this.searckKey,
                status: this.sortType,
                page: this.gPage
            }
            searchPresale(params).then(res => {
                this.islock = false
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                }else{
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },

        // 店铺搜索
        searchMerchant() {
            this.aloading = true
            let params = {
                search: this.searckKey,
                status: this.sortType,
                shops_id: this.merchantId,
                page: this.gPage
            }
            searchMerchant('shops', params).then(res => {
                this.islock = false
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                }else{
                    this.noMore = true
                }
            }).catch(() => {
                this.goodsList = []
            }).finally(() => {
                this.aloading = false
            })
            this.cutSearch()
        },


        getFavoriteGoodsListHandle() {
            this.fislock  = true
            this.floading = true
            getFavoriteGoodsList({page: this.fpage}).then(res => {
              if(res) {
                this.favoriteGoodsList = this.favoriteGoodsList.concat(res.data.data)
                if(res.data.data.length < res.data.per_page) {
                  this.finished = true
                }
              } else {
                this.finished = true
              }
            }).finally(() => {
              this.floading = false
              this.fislock  = false
            })
        },
        // 猜你要搜
        getGuessLike() {

        }
    },
    // beforeRouteEnter(to, from, next) {
    //     // 保存上一个页面路由
    //     localStorage.setItem('back_path', from.fullPath)
    //     next()
    // },
    activated() {
        this.gPage = 1
        this.searchType = this.$route.query.s
        if(this.$route.query.skey && this.searchType != this.$route.query.skey) {
            // 重新获取数据
            this.searchKeyHandle(this.$route.query.skey)
        }
         // 复制搜索
        if (this.$route.query.searchKey) {
            this.searchKeyHandle(this.$route.query.searchKey)
        }
        if(this.searchType == 'gift') {
            // 礼物专区搜索
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.isSearch = true
            this.judgeSearchMethods()
        } else if (this.searchType == 'merchant') {
            // 店铺搜索
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.merchantId = this.$route.query.id
            this.isSearch = true
            this.judgeSearchMethods()
        } else if (this.searchType == 'presale') {
            this.gPage = 1
            this.goodsList = []
            this.searckKey = this.$route.query.key
            this.isSearch = true
            this.judgeSearchMethods()
        }

        // this.getFavoriteGoodsListHandle()
        this.getGuessLike()
    },
    created() {
        // 正常搜索
        // if(this.$route.query.skey) {
        //     this.searchKeyHandle(this.$route.query.skey)
        // }
        //  // 复制搜索
        //  if (this.$route.query.searchKey) {
        //     this.searchKeyHandle(this.$route.query.searchKey)
        // }
        this.getHotSearchHandle()
        this.getHistoryHandle()
        // this.getGuessLike()
    }
}